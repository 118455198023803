input {
    display: flex;
    width: 100%;
    height: 44px;
    /* right: 35%; */
    background-color: transparent;
    border: 7px solid #19c910;
    border-radius: 131px;
    opacity: 1;
    position: relative;
    text-align: right;
    font-family: Almarai;
    font-size: 25px;
    letter-spacing: 0px;
    color: #001B0B;
    padding-right: 10px;
    outline: none;
}

