/* General Styles */
.navbar-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 53px;
    width: 100%;
    height: 75px;
    font-size: 1.2rem;
    position: fixed;
    padding: 0;
    background: #fff;
    box-shadow: 0px 12px 15px rgba(31, 29, 29, 0.07);
    border-radius: 50px;
    opacity: 1;
    z-index: 9;
}

.logo-mor {
    width: 140px;
    height: 210px;
    margin-left: 40px;
}

.nav-menu {
    display: grid;
    font: bold 1rem Almarai;
    grid-template-columns: repeat(9, auto);
    grid-gap: 20px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
    direction: rtl;
    padding: 0 30px 0 40px;
}

.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    background: #fff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    border-radius: 59px;
    transition: background 0.3s ease;
}

.nav-link:hover {
    background: #FFBB00;
}

.nav-link.active {
    background-color: #D7E304;
}

img {
    height: 30px;
    width: 30px;
    margin-top: 10px;
    margin-left: -10px;
}

.menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.menu span {
    height: 4px;
    width: 35px;
    background: black;
    margin: 3px 0;
    border-radius: 2px;
}

/* Media Queries for Responsive Design */

/* Medium screens */
@media (max-width: 1300px) {
    .navbar-item {
        margin-top: 43px;
        height: 75px;
    }

    .logo-mor {
        width: 120px;
        height: 180px;
        margin-left: 40px;
    }
    
    .nav-menu {
        grid-template-columns: repeat(9, auto);
        grid-gap: 10px;
        padding: 0;
    }
    .nav-link {
        font-size: 0.9rem;
    }

    .home-logo {
        margin-left: 5px;
    }
}

/* Small screens */
@media (max-width: 1200px) {
    .navbar-item {
        margin-top: 38px;
        height: 65px;
        justify-content: space-between;
    }

    .logo-mor {
        width: 110px;
        height: 170px;
        margin-left: 40px;
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .nav-menu.open {
        display: grid;
        grid-template-columns: repeat(1, auto);
        justify-content: start;
        align-items: center;
        margin-top: 350px;
        gap: 0;
        max-height: 500px;
    }
    .nav-link {
        font-size: 0.8rem;
        width: 80px;
        height: 40px;
        margin: 5px 0;
    }
    .menu {
        display: flex;
        padding-right: 30px;
    }
    
    .home-logo {
        display: none;
    }
}

/* Extra small screens */
@media (max-width: 880px) {
    .navbar-item {
        margin-top: 28px;
        height: 40px;
    }

    .logo-mor {
        width: 80px;
        height: 130px;
        margin-left: 40px;
    }

    .nav-menu {
        grid-template-columns: repeat(2, auto);
        grid-gap: 5px;
    }
    .nav-link {
        font-size: 0.7rem;
        width: 70px;
        height: 35px;
    }
    .home-logo, .logo {
        width: 60px;
        height: auto;
    }
}

/* Ultra small screens */
@media (max-width: 600px) {
    .navbar-item {
        margin-top: 12px;
        height: 30px;
    }

    .logo-mor {
        width: 40px;
        height: 70px;
        margin-left: 40px;
    }

    .menu span {
        height: 4px;
        width: 25px;
        background: black;
        margin: 2px 0;
        border-radius: 2px;
    }
}
